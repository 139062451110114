.watchContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
.introSubtitle {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #c98028;
}
.title {
    font-size: 64px;
    line-height: 74px;
    font-weight: 700;
    color: #fff;
    width: 74vw;
    text-align: center;
    overflow-y: hidden;
}
.videoBg {
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
.videoBgContainer{
    flex: 1;
    width: 100vw;
    height: 100vh;
}
.videoBgContainer:after{
    content: ''; 
    display: block; 
    height: 100%; 
    background: rgba(48, 79, 146, 0.7);
    mix-blend-mode: multiply
}
.info {
    position: absolute;
}
.actionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.actionContainer h4 {
    color: #c98028;
}
.playButton {
    content: url('../images/play.svg'); 
    width: 60px;
    margin-left: 50px;
}
.playerContainer {
    position: absolute;
}