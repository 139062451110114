.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 200vh;
    margin-top: -80px;
}
.subtitle {
    color: #C98028;
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    text-align: center;
    margin: 80px 0 20px;

}
.title {
    font-size: 44px;
    line-height: 64px;
    font-weight: 700;
    color: #2b5597;
    text-align: center;
    margin-bottom: 50px;
}
.contentContainer {
    width: 70vw;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    box-shadow: -3px 7px 45px -20px rgba(92,74,74,0.49);
    -webkit-box-shadow: -3px 7px 45px -20px rgba(92,74,74,0.49);
    -moz-box-shadow: -3px 7px 45px -20px rgba(92,74,74,0.49);
}
.content {
    width: 40vw;
    font-size: 21px;
    line-height: 34px;
    color: #464646;
    font-weight: 400;
    text-align: center;
}
.hero {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #2b5597;
    margin: 50px 10px 0;
}
.heroLocation {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #2b5597;
}
.heroPicture {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    position: absolute;
    margin-top: 800px;
}